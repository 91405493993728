import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import SEO from '@components/Seo';
const PrivacyPolicy = ({
    data: {
        page: {
            frontmatter: { title },
            html,
        },
    },
}) => {
    return (
        <Layout>
            <SEO title="Privacy Policy" />

            <div className={`${styles.policyMain} max-w-6xl px-5 mx-auto py-12`}>
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title
            }
            html
        }
    }
`;

export default PrivacyPolicy;
